
import { defineComponent, reactive, toRaw } from 'vue';
import { useForm } from 'ant-design-vue/es/form';
import { notification } from 'ant-design-vue';

export default defineComponent({
  name: 'TaskForm',
  props: {
    showSubmit: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const modelRef = reactive({
      name2: undefined,
      url2: undefined,
      owner2: undefined,
      approver2: undefined,
      dateRange2: undefined,
      type2: undefined,
    });
    const rulesRef = reactive({
      name2: [{ required: true, message: '请输入任务名称', whitespace: true }],
      url2: [{ required: true, message: '请输入任务描述', whitespace: true }],
      owner2: [{ required: true, message: '请选择执行人' }],
      approver2: [{ required: true, message: '请选择责任人' }],
      dateRange2: [{ required: true, message: '请选择提醒时间' }],
      type2: [{ required: true, message: '请选择任务类型' }],
    });
    const { resetFields, validate, validateInfos } = useForm(modelRef, rulesRef);

    const handleSubmit = (e: Event) => {
      e.preventDefault();
      validate().then(() => {
        notification['error']({
          message: 'Received values of form:',
          description: toRaw(modelRef),
        });
      });
    };

    return {
      modelRef,
      validate,
      validateInfos,
      resetFields,

      handleSubmit,
    };
  },
});
